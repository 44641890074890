/* Contact.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #121212;
  color: #e0e0e0;
}

.contact-container {
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  max-width: 600px;
  margin: 40px auto;
  background-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.3s ease;
}

.contact-container:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
}

h2 {
  text-align: center;
  color: #ffcc00;
  margin-bottom: 20px;
  font-weight: 700;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

label {
  font-weight: 700;
  color: #e0e0e0;
}

input,
textarea,
select {
  padding: 15px;
  border: 1px solid #333;
  border-radius: 5px;
  background-color: #2e2e2e;
  color: #e0e0e0;
  font-size: 1em;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input:focus,
textarea:focus,
select:focus {
  border-color: #ffcc00;
  box-shadow: 0 0 5px rgba(255, 204, 0, 0.5);
  outline: none;
}

textarea {
  resize: vertical;
  min-height: 100px;
}

button {
  padding: 15px 20px;
  background-color: #ffcc00;
  color: #1a1a1a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
  font-weight: 700;
}

button:hover {
  background-color: #e6b800;
}

button:active {
  background-color: #cc9900;
}

.file-list {
  list-style: none;
  padding: 0;
}

.file-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2e2e2e;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.file-list li button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
}

.file-list li button:hover {
  background-color: #cc0000;
}
