@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #121212;
  color: #e0e0e0;
}

.about-container {
  max-width: 1000px;
  margin: 80px auto 40px;
  padding: 20px;
  background-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(228, 22, 22, 0.559);
}

h1 {
  text-align: center;
  color: #ffcc00;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 2.5em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.section-container {
  position: relative;
  margin-bottom: 40px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.section-container::before {
  content: '';
  display: block;
  padding-top: 56.25%;
}

.section-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: brightness(0.7);
  z-index: 0;
}

.about-content {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 20px;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 100%;
  box-sizing: border-box; /* Ensure padding and width work together */
}

.about-content h3 {
  color: #ffcc00;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 2em;
}

.read-more-button {
  background-color: #ffcc00;
  color: #1e1e1e;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 250px;
  margin: 20px auto; /* Center the button */
  display: block; /* Make sure the button is a block element */
}

.read-more-button:hover {
  background-color: #e6b800;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.read-more-button:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(255, 204, 0, 0.5);
}

.about-content p {
  color: #ffffff;
  font-size: 1.2em;
}

.section-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  transition: max-height 0.3s ease;
}

.section-container.expanded .section-description {
  white-space: normal;
  max-height: none;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #1e1e1e;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  color: #e0e0e0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  position: relative;
}

.modal-content h3 {
  margin-top: 0;
  color: #ffcc00;
}

.modal-content p {
  font-size: 1.2em;
}

.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: brightness(0.7);
  z-index: -1;
}

.modal-content .text-content {
  position: relative;
  z-index: 1;
}

@media (max-width: 768px) {
  .about-container {
    margin: 30px 20px;
    padding: 15px;
  }

  h1 {
    font-size: 1.9em;
  }

  .about-content h3 {
    font-size: 1.5em;
  }

  .about-content p {
    font-size: 1em;
  }

  .read-more-button {
    width: calc(100% - 40px); /* Full width minus margins */
    margin: 10px auto; /* Center the button with auto margins */
    max-width: none; /* Remove the max-width restriction */
    padding: 10px; /* Adjust padding for smaller screens */
  }
}
