.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #1e1e1e;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 90%;
  color: #e0e0e0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  position: relative;
  text-align: center;
}

.modal-content h2 {
  margin-top: 0;
  color: #ffcc00;
}

.modal-content p {
  font-size: 1.2em;
}

.modal-close {
  padding: 10px 20px;
  background-color: #ffcc00;
  color: #1a1a1a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
  position: absolute;
  top: 20px;
  right: 20px;
}

.modal-close:hover {
  background-color: #e6b800;
}

.modal-image {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}
