@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

nav {
    font-weight: bold;
    color: white;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition:  0.3s ease;
    padding: 1rem 2rem;
    margin: 0 auto;
    height: 70px;
    background: linear-gradient(135deg, #16212f79, #03070f8a, #000000);
    box-sizing: border-box;
    position: fixed;
}

nav.scrolled {
    background: linear-gradient(135deg, #00000054, #00000158, #02010336);
    backdrop-filter: blur(10px);
}

nav .logo-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

nav .logo {
    height: 4rem;
    width: 4rem;
    max-width: 100%;
    border-radius: 50%;
    border: 3px solid #2b00ff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

nav .logo:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.7);
}

nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

nav li {
    margin: 0 1rem;
}

nav a, nav button {
    color: #ffffff;
    text-decoration: none;
    font-size: 1.2rem;
    position: relative;
    transition: color 0.3s ease, background-color 0.3s ease; /* Add transition for background color */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: none;
    border: none;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    padding: 0.5rem 1rem; /* Add padding for better click area */
    border-radius: 5px; /* Add border-radius for smoother edges */
}

nav a:after, nav button:after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: red; /* Red underline on hover */
    transition: width 0.4s ease;
}

nav a:hover, nav button:hover {
    color: #ffffff; /* Ensure text color stays white */
    background-color: #1e3c72; /* Dark blue background on hover */
}

nav a:hover:after, nav button:hover:after {
    width: 100%;
    left: 0;
    background: red; /* Red underline on hover */
}

nav .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    margin-right: 1rem;
    z-index: 11;
}

nav .hamburger div {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
    transition: all 0.3s ease;
}

@media (max-width: 768px) {
    nav {
        padding: 0.5rem 1rem;
        height: 50px;
    }

    nav .logo {
        height: 3rem;
        width: 3rem;
    }

    nav ul {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 50px;
        right: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.9);
        padding: 1rem;
        margin: 0;
        z-index: 9;
    }

    nav ul.show {
        display: flex;
    }

    nav li {
        margin: 1rem 0;
        text-align: center;
    }

    nav .hamburger {
        display: flex;
    }

    nav .hamburger.active div:nth-child(1) {
        transform: rotate(-45deg) translate(-5px, 6px);
    }

    nav .hamburger.active div:nth-child(2) {
        opacity: 0;
    }

    nav .hamburger.active div:nth-child(3) {
        transform: rotate(45deg) translate(-5px, -6px);
    }
}

@media (max-width: 480px) {
    nav a, nav button {
        font-size: 1rem;
    }

    nav .logo {
        height: 2.5rem;
        width: 2.5rem;
    }

    nav .hamburger {
        margin-right: 0.5rem;
    }

    nav {
        height: 50px;
        padding: 0.5rem 1rem;
    }

    nav ul {
        top: 50px;
        width: 100%;
        padding: 1rem;
        margin: 0;
    }
}
