@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #121212;
  color: #e0e0e0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.main-header {
  background: url('../image/wallpaper.jpg') no-repeat center center;
  background-size: cover;
  padding: 100px 20px;
  color: white;
  text-align: center;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  filter: brightness(1.5) contrast(0.9); /* Adjust these values to increase brightness and contrast */
}

.main-header::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.main-header-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.main-header-content h1 {
  font-size: 3em;
  margin: 0;
  font-weight: 700;
  color: #ffcc00;
}

.main-header-content p {
  font-size: 1.5em;
  margin: 10px 0;
  color: #e0e0e0;
}

.section {
  margin: 60px 0;
  text-align: center;
}

.section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #ffcc00;
}

.section p {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #cccccc;
}

.services {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.service {
  background-color: #333;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.service:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7);
}

.service-image {
  width: 100%;
  height: 200px; /* Set a fixed height */
  object-fit: cover; /* Ensures the image covers the container without distortion */
  border-radius: 10px;
  margin-bottom: 15px;
}

.service h3 {
  font-size: 1.8em;
  margin-bottom: 15px;
  color: #ffcc00;
}

.service p {
  font-size: 1.1em;
  margin-bottom: 15px;
  color: #cccccc;
}

.service button {
  text-decoration: none;
  color: #1a1a1a;
  background-color: #ffcc00;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-block;
  border: none;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
  margin-top: auto; /* Ensures button is pushed to the bottom */
}

.service button:hover {
  background-color: #e6b800;
}

.testimonial {
  margin: 40px 0;
  text-align: center;
}

.testimonial p {
  font-size: 1.2em;
  color: #cccccc;
}

.contact-cta {
  margin: 60px 0;
  text-align: center;
  background-color: #333;
  color: white;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.contact-cta h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #ffcc00;
}

.contact-cta p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.contact-cta button {
  padding: 15px 30px;
  background-color: #ffcc00;
  color: #1a1a1a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

.contact-cta button:hover {
  background-color: #e6b800;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #1e1e1e;
  padding: 20px;
  border-radius: 10px;
  max-width: 800px;
  width: 90%;
  color: #e0e0e0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  position: relative;
}

.modal-content h2 {
  margin-top: 0;
  color: #ffcc00;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-image {
  width: 300px; /* Fixed size for the image */
  height: 300px; /* Fixed size for the image */
  object-fit: cover;
  border-radius: 50%; /* Makes the image circular */
  margin-bottom: 20px;
}

.modal-content p {
  font-size: 1.2em;
  text-align: center;
}

.modal-close {
  padding: 10px 20px;
  background-color: #ffcc00;
  color: #1a1a1a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
  position: absolute;
  top: 20px;
  right: 20px;
}

.modal-close:hover {
  background-color: #e6b800;
}

/* Responsive Design */
@media (max-width: 768px) {
  .main-header-content h1 {
    font-size: 2.2em;
  }

  .main-header-content p {
    font-size: 1.2em;
  }

  .section h2 {
    font-size: 2em;
  }

  .section p,
  .testimonial p,
  .contact-cta p {
    font-size: 1em;
  }

  .contact-cta h2 {
    font-size: 2em;
  }

  .contact-cta button {
    font-size: 1em;
    padding: 10px 20px;
  }

  .modal-image {
    width: 200px;
    height: 200px;
  }
}
