/* Footer.css */
.footer {
  background-color: #1e1e1e;
  color: #e0e0e0;
  padding: 40px 20px;
  text-align: center;
  border-top: 1px solid #333;
}

.footer-container {
  max-width: 1000px;
  margin: 0 auto;
}

.footer a {
  color: #ffcc00;
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s ease;
  font-size: 1.2em;
}

.footer a:hover {
  color: #e6b800;
}

.footer p {
  margin: 10px 0;
}

.footer p a {
  color: #ffcc00;
  text-decoration: none;
  margin: 0 5px;
  font-size: 1.5em;
  vertical-align: middle;
}

.footer p a:hover {
  color: #e6b800;
}

.footer i {
  margin-right: 5px;
}

@media (max-width: 768px) {
  .footer {
    padding: 20px 10px;
  }

  .footer a {
    margin: 0 5px;
  }
}
